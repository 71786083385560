/* eslint-disable prefer-template */
/**
 * 常用工具函数（ES6语法）
 * cookie
 * url params
 * date
 * time
 * 身份证校验
 * 国际化配置文件转map格式
 * more……
 * 此文件中的函数需注明作者
 */
import type { Dayjs } from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { InjectedIntl } from 'react-intl';
import { dayjsToString, getTimeZone, stringToDayjs } from 'scc-oms-components';
import { DEFAULT_DATETIME_FORMAT_STRING, DEFAULT_TIME_ZONE } from './constants';
/**
 * [设置cookie]
 * @param {[string]} cookie key
 * @param {[string]} cookie value
 * @author lichun
 */
export function setCookie(name: string, value: string): void {
  const now = new Date();
  now.setTime(now.getTime() + 1000 * 60 * 60 * 24 * 30);
  // tslint:disable-next-line: no-console
  // console.log('在设置cookie时新增了SameSite=Lax;在某些情况下可能有限制，关注');
  const str = `${name}=${value};expires=${now.toUTCString()};path=/;SameSite=Lax;`;
  document.cookie = str;
}

/**
 * [得到cookie]
 * @param {[string]} cookie key
 * @returns {[string]} value
 * @author lichun
 */
export function getCookie(name: string) {
  let start;
  let end;

  if (document.cookie.length > 0) {
    start = document.cookie.indexOf(`${name}=`);

    if (start !== -1) {
      start = start + name.length + 1;
      end = document.cookie.indexOf(';', start);
      if (end === -1) {
        end = document.cookie.length;
      }
      return unescape(document.cookie.substring(start, end));
    }
  }
  return '';
}

/**
 * 从url中获取参数值
 * @param {string} 参数名
 * @return {string} 参数值
 * @author lichun 正则自己写的，不保证所有情况下都正确 ^-^ 凑合着用
 */
export function getQueryString(name: string) {
  const reg = new RegExp(`([?&])${name}=([^&]*?)(#|&|$)`, 'i');
  const r = window.location.href.match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return '';
}

/**
 * URL参数反序列化
 * @param {string} 选填 参数名默认取location.search
 * @return {object} 参数key-value对象
 * @author lf
 */
export const parseUrlSearch = (params: string) =>
  (params || window.location.search)
    .replace(/(^\?)|(&$)/g, '')
    .split('&')
    .reduce((t, v) => {
      const [key, val] = v.split('=');
      // eslint-disable-next-line no-param-reassign
      t[key] = decodeURIComponent(val);
      return t;
    }, {} as TDictionary<string>);
/**
 * object转url参数
 * @param {object}
 * @return {string}
 * @author yangyang
 */
export function setUrlQuerys(params: Record<string, string>) {
  const queryString = Object.keys(params)
    .map((item: string) => `${encodeURIComponent(item)}=${encodeURIComponent(JSON.stringify(params[item]))}`)
    .join('&');
  return queryString;
}

/**
 * 时间戳转换
 * @param {String} date eg: 1234567890
 * @return {String} 2017-07-27 10:10:10
 * @author zhangning
 */
export function secondToDatetime(timestap: number) {
  const date = new Date(timestap * 1000);
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
  const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

/**
 * 判断页面类型：创建、修改、详情
 * @param flag OperateType
 */
export function isView(flag: TOperateType) {
  return flag === 'view';
}
export function isModify(flag: TOperateType) {
  return flag === 'edit';
}
export function isCreate(flag: TOperateType) {
  return flag === 'create';
}

/**
 * 表格行枚举映射
 * @param messageMap messages定义的枚举
 * @param propKey 对应的key
 */
export const transToRowRender = (intl: InjectedIntl, messageMap: Record<string, any>, propKey: number | string) => {
  let result = '';
  Object.keys(messageMap).forEach((key: string) => {
    if (Number(key) === propKey) {
      // eslint-disable-next-line
      result = intl.formatMessage((messageMap as any)[key]);
    }
  });
  return result;
};

/**
 * 国际化配置文件转map格式
 * 用法参考Demo文件夹
 * @param message
 */

type TGetDefaultMessage<Type> = Type extends Record<string, { id: string; defaultMessage: infer Item }> ? Item : string;

export const i8nMessageToMap = <messages>(message: { [key: string]: TMsg }): Map<TGetDefaultMessage<messages>, TMsg> =>
  new Map(
    Object.values(message).reduce((res, item, index) => {
      res[index] = [item.defaultMessage, item];
      return res;
    }, []),
  );

/**
 * 表格行枚举映射
 * 针对基础代码中的枚举字段
 * @param messageMap 字段映射关系数组
 * @param propKey 对应的key
 * @param undefinedTxt 基础代码中未定义的值显示的文案，默认为 '-'
 * @param voidTxt 空值显示的文案，默认为 '-'
 */
export const transEnumToRowRender = (
  messageMap: IConfListParams[],
  propKey: React.Key,
  undefinedTxt = '-',
  voidTxt = '-',
) => {
  const key = String(propKey);
  // 如果 key 为空，则显示空值文案
  if (!key) {
    return voidTxt;
  }
  if (Array.isArray(messageMap)) {
    const selectedItem = messageMap.find(item => String(item.data_code) === key);
    // 匹配成功则返回具体的文案，没有匹配成功则返回未定义值的文案
    return selectedItem === void 0 ? undefinedTxt : selectedItem.custom_value;
  }
  return voidTxt;
};

/**
 * 表格行数组多枚举值映射
 * 主要针对基础代码中的枚举字段
 * @param messageMap 字段映射关系数组
 * @param keys 多枚举值数组 or 多枚举字符串
 * @param undefinedTxt 基础代码中未定义的值显示的文案，默认为 '-'
 * @param voidTxt 空值显示的文案，默认为 '-'
 */
export const transMultiEnumsToRowRender = (
  messageMap: IConfListParams[],
  keys: React.Key[] | string,
  undefinedTxt = '-',
  voidTxt = '-',
) => {
  const keyList = Array.isArray(keys) ? keys.map(item => String(item)) : keys.split(/,|，/);
  if (!keyList.length) {
    return voidTxt;
  }
  const resList: string[] = [];
  keyList.forEach(key => {
    const selectedItem = messageMap.find(item => String(item.data_code) === key);
    resList.push(selectedItem === void 0 ? undefinedTxt : selectedItem.custom_value);
  });
  return resList.join(',') || voidTxt;
};

/**
 * InputNumber 小数有效位数格式化
 * @param value Input的输入
 * @example <InputNumber step={0.001} formatter={limitDecimals} parser={limitDecimals} />
 */
export const limitDecimals = (value: string | number): string => {
  // eslint-disable-next-line
  const reg = /^(\-)*(\d+)\.(\d\d\d).*$/;
  if (typeof value === 'string') {
    return !Number.isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
  }
  if (typeof value === 'number') {
    return !Number.isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
  }
  return '';
};

/**
 * @example <InputNumber step={0.000001} formatter={limitDecimals} parser={limitDecimals} />
 */
export const limitMoney = (value: string | number): string => {
  // eslint-disable-next-line
  const reg = /^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/;
  if (typeof value === 'string') {
    return !Number.isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
  }
  if (typeof value === 'number') {
    return !Number.isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
  }
  return '';
};

/**
 * 下划线名称转为大写驼峰名
 */
export function upperCaseName(name: string) {
  if (!name || typeof name !== 'string') {
    return '';
  }
  return name
    .split('_')
    .map((item: string) => `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`)
    .join('');
}

/**
 * 大写驼峰名转为下划线名称
 *
 */
export function changeLineName(name: string) {
  if (!name || typeof name !== 'string') {
    return '';
  }
  return name.replace(/([A-Z])/g, '_$1').toLowerCase();
}

/**
 * 判断是否是JSON字符串
 * @returns {boolean} 判断结果
 * @param str 字符串入参
 */
export function isJSON(str: string): boolean {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

/**
 * JSON 格式化显示
 * @param json json对象/字符串
 * @returns jsong格式化dom
 */
export function syntaxHighlight(json: any) {
  let jsonClone = cloneDeep(json);
  if (typeof jsonClone !== 'string') {
    jsonClone = JSON.stringify(json, undefined, 2);
  }
  jsonClone = jsonClone.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  // eslint-disable-next-line
  return jsonClone.replace(
    // eslint-disable-next-line no-useless-escape
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    (match: any) => {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    },
  );
}

/**
 * 有效时间格式校验：时分格式 HH:mm
 * @param str 时间字符串
 * @returns res boolean
 */
export function isTimeByMin(str: string) {
  const res: string[] = str.match(/^([01]\d|2[0-3]):([0-5]\d)$/);
  if (res == null) {
    return false;
  }
  if (Number(res[1]) > 24 || Number(res[2]) > 60) {
    return false;
  }
  return true;
}

/**
 * 有效时间格式校验：时分秒格式 HH:mm:ss
 * @param str 时间字符串
 * @returns res boolean
 */
export function isTimeBySecond(str: string) {
  const res: string[] = str.match(/^(\d{1,2})(:)?(\d{1,2})\2(\d{1,2})$/);
  if (res == null) {
    return false;
  }
  if (Number(res[1]) > 24 || Number(res[3]) > 60 || Number(res[4]) > 60) {
    return false;
  }
  return true;
}

/**
 * iframe中获取父亲的url
 */
export function getParentUrl() {
  // eslint-disable-next-line eqeqeq
  return window.location != window.parent.location ? document.referrer : document.location.href;
}

/**
 * 递归删除对象中指定的某个key
 * @param _obj 对象
 * @param name key
 * @description 注意：这个方法并不是一个纯函数，会改变入参
 */
export function removeSomeProperty(_obj: TDictionary<any>, name: string) {
  Object.keys(_obj).forEach(key => {
    if (key === name) {
      // eslint-disable-next-line no-param-reassign
      delete _obj[key];
    } else if (typeof _obj[key] === 'object' && _obj[key] !== null) {
      removeSomeProperty(_obj[key], name);
    }
  });
}

/**
 * 列表日期时间字符串时区转换
 * @description 东八区转换成当前所在时区
 * @param value string
 * @returns string
 */
export function renderLocaleDateTimeString(value: string) {
  // 一些无效的格式直接排除
  if ([null, undefined, '', false, 0, '0000-00-00 00:00:00'].includes(value)) {
    return '-';
  }
  // 兼容逻辑：如果格式不是YYYY-MM-DD HH:mm:ss，则不处理直接返回原值
  if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(value)) {
    return value;
  }
  const res = stringToDayjs(value, DEFAULT_DATETIME_FORMAT_STRING, DEFAULT_TIME_ZONE);
  return dayjsToString(res, DEFAULT_DATETIME_FORMAT_STRING, getTimeZone(), true);
}

/**
 * 将表单项 RangePicker 的值从dayjs对象转换为格式化字符串；
 * @param dayjsValues RangePicker的值
 * @param format 格式化字符串
 * @param timeZone 时区，默认为当前所在时区
 */
export function dayjsArr2string(dayjsValues: Dayjs[], format: string, timeZone = getTimeZone()) {
  const res = [];
  res[0] = dayjsToString(dayjsValues[0], format, timeZone);
  res[1] = dayjsToString(dayjsValues[1], format, timeZone);
  return res;
}
/**
 * 将 UTC+8 时区的日期字符串转换为本地时区的字符串
 *
 * @param val 要转换的日期字符串
 * @param format 目标格式字符串
 * @returns 本地时间的字符串表示，如果输入字符串无效，则返回 undefined
 */
export function utc8String2LocaleString(val: string, format: string) {
  if ([null, undefined, ''].includes(val)) {
    return undefined;
  }
  const res = stringToDayjs(val, format, DEFAULT_TIME_ZONE);
  return res.format(format);
}
